<template>
    <div class="all-row">
        <div id="all" href="#" role="button">
            <h2>
                {{ chave }}
            </h2>

            <img class="next" src="../assets/img/next.png" alt="" />
        </div>
        <span class="numbers">
            {{ Object.keys(tarefa).length }}
        </span>
    </div>
</template>

<script>
export default {
    props: ['tarefa', 'chave'],
};
</script>

<style scoped>
.all-row {
    align-items: center;
    position: relative;
}

#all {
    width: 100%;
}

#all h2 {
    color: #707070;
    overflow: hidden;
    max-width: 21ch;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.numbers {
    font-size: 12px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--verde);
    color: var(--verde);
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
    position: absolute;
    right: 18px;
    top: -3px;
}

.next {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    top: 30%;
}
</style>
