<template>
    <div>
        <div class="container">
            <router-link
                v-for="(tarefa, key) in tarefas"
                :key="key"
                class="div-alerts"
                :to="{ name: 'tarefas.validade', params: { categoria: key } }"
            >
                <tag-produto :tarefa="tarefa" :chave="key"></tag-produto>
            </router-link>
        </div>
    </div>
</template>

<script>
import TagProduto from '../components/TagProduto';

export default {
    components: {
        TagProduto,
    },

    data() {
        return {
            tarefas: null,
        };
    },

    computed: {
        getCategorias() {
            return this.$store.getters['tarefas/getCategorias'];
        },
    },

    created() {
        this.tarefas = this.getCategorias;
    },
};
</script>

<style scoped>
.container {
    margin-top: 66px;
    margin-bottom: 86px;
}

.div-alerts {
    display: block;
    padding: 15px;
    margin: 15px 10px;
    border-radius: 4px;
    box-shadow: 0 0 5px #9b9b9b;
    background-color: #f4f4f4;
}
</style>
